<template>
  <b-row>
    <b-col md="12">
      <h4 class="float-left mr-2 h2">{{ project.name }} - </h4>
      <p class="mt-1 h3">
        Longueur de tissus requis
        <b-badge class="print-text-black" variant="success">{{ requiredFabric }}</b-badge>
        |
        Efficience
        <b-badge class="print-text-black" v-if="pack" variant="secondary">{{ efficiency }}%</b-badge>
        |
        Laize
        <b-badge class="print-text-black" variant="primary">{{ laize }}</b-badge>
        <router-link :to="{name:'editor', params:{project_id: $route.params.project_id}}" class="float-right no-print">
          <b-button size="sm">Editer
            <pencil/>
          </b-button>
        </router-link>
        <b-button class="float-right mr-2 no-print" size="sm" variant="primary" @click="print">
          <printer/>
        </b-button>
        <special-rules @onRulesApplied="resetDraw" class="float-right mr-2 no-print"/>
      </p>
    </b-col>
    <canvas v-if="displayCanvas" id="disposition-canvas" :style="canvasStyle" resize></canvas>
  </b-row>

</template>
<script>

import {mapGetters, mapState} from 'vuex'
import paper from 'paper'
import Pencil from 'vue-material-design-icons/Pencil.vue'
import Printer from 'vue-material-design-icons/Printer.vue'
import SpecialRules from './SpecialRules.vue'
import Packer from '../lib/packer'

export default {
  components: {
    Pencil, Printer, SpecialRules
  },
  data() {
    return {
      requiredFabric: 0,
      loading: false,
      pack: undefined,
      displayCanvas: true,
    }
  },
  created() {
    this.$store.commit('projects/setCurrentProjectId', this.$route.params.project_id)
  },
  mounted() {
    this.displayPieces()
  },
  computed: {
    ...mapState('projects', ['projects']),
    ...mapGetters('projects', ['project']),
    pieces() {
      return this.project.pieces
    },
    coefficient() {
      return ((window.innerWidth - 35) * 1) / this.laize
    },
    laize() {
      return parseInt(this.project.laize)
    },
    canvasStyle() {
      return {height: this.totalHeight}
    },
    efficiency() {
      const usedSurface = this.pack.reduce((count, piece) => {
        return count + (piece.w * piece.h)
      }, 0)
      return (usedSurface / (this.laize * this.packMaxHeight) * 100).toFixed(0)
    },
    packMaxHeight() {
      let computedMaxHeight = 0
      for (const rect of this.pack) {
        if (rect.h + rect.y > computedMaxHeight) {
          computedMaxHeight = rect.h + rect.y
        }
      }
      return computedMaxHeight
    },
  },
  methods: {
    resetDraw() {
      this.displayCanvas = false
      this.$forceUpdate()
      this.$nextTick(() => {
        this.displayCanvas = true
        this.$nextTick(() => {
          this.displayPieces()
        })
      })
    },
    print() {
      print()
    },
    repaint() {
      const darkColor = '#444'
      const c = this.coefficient
      paper.setup(this.$el.querySelector('#disposition-canvas'));
      const colors = ["#C5FFAB", "#FFF7A5", "#FFA5E0", "#A5B3FF", "#BFFFA5", "#00E7FF", "#DEEE8A", "#FFC77B", "#68EDCB", "#FF839D"]
      for (const block of this.pack) {
        const rect = new paper.Rectangle(block.x * c, block.y * c, block.w * c, block.h * c);
        const path = new paper.Path.Rectangle(rect, 0);
        const color = colors.pop()
        path.strokeColor = darkColor
        path.fillColor = color
        colors.unshift(color)
        const text = new paper.PointText(new paper.Point(block.x * c + 3, (block.y * c) + (block.h * c / 2) - 6));
        text.fillColor = darkColor;
        if (block.w > 10 && block.h > 10) {
          text.fontSize = "2em"
        }
        text.content = `L:${block.w} | H:${block.h}\n${block.name}`;
      }
    },
    applySpecialRules() {
      const pieceFromId = {}
      for (const piece of this.pack) {
        pieceFromId[piece.name] = piece
      }
      const ruleFromId = {}
      for (const rule of this.project.specialRules || []) {
        ruleFromId[rule.source] = rule
      }
      const packs = JSON.parse(JSON.stringify(this.pack))
      const packsWithRules = []
      for (let pack of packs) {
        const rule = ruleFromId[pack.name]
        if (rule) {
          if (rule.rightOf) {
            pack.x = pieceFromId[rule.rightOf].x + pieceFromId[rule.rightOf].w
          }
          if (rule.bottomOf) {
            pack.y = pieceFromId[rule.bottomOf].y + pieceFromId[rule.bottomOf].h
          }
        }
        packsWithRules.push(pack)
      }
      return packsWithRules
    },
    displayPieces() {
      const packer = new Packer(this.laize, Infinity)
      let pack = JSON.parse(JSON.stringify(this.pieces)).map(p => {
        return {
          w: parseInt(p.width),
          h: parseInt(p.height),
          name: p.name,
        }
      })
      const sort = {
        w: (a, b) => {
          return b.w - a.w;
        },
        h: (a, b) => {
          return b.h - a.h;
        },
        height: (a, b) => {
          return sort.msort(a, b, ['h', 'w']);
        },
        msort: (a, b, criteria) => {
          let diff;
          for (let crit of criteria) {
            diff = sort[crit](a, b);
            if (diff !== 0)
              return diff;
          }
          return 0;
        },
      };

      pack.sort(sort.height);
      packer.fit(pack)
      this.pack = pack.map(p => {
        return {
          x: p.fit.x,
          y: p.fit.y,
          w: p.w,
          h: p.h,
          name: p.name,
        }
      })
      this.pack = this.applySpecialRules()
      this.$el.querySelector('#disposition-canvas').style.width = (this.laize * this.coefficient) + 'px'
      this.$el.querySelector('#disposition-canvas').style.height = (this.packMaxHeight * this.coefficient) + 'px'
      this.requiredFabric = this.packMaxHeight
      this.repaint()

    }
  }
}
</script>
<style scoped>
canvas[resize] {
  width: 100%;
  background-color: #eee;
}

canvas {
  border: 1px solid #ccc;
  margin: 10px;
}

@media print {
  .print-text-black {
    color: black !important;
  }
}
</style>
