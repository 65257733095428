import Vue from 'vue'

export default {
    namespaced: true,
    state: {},
    actions: {
        backup(_, {projects, backupToken}) {
            return Vue.axios.post('https://backupfiles-xfpg5jduea-ew.a.run.app/data', {projects}, {headers: {identifier: backupToken}})
        }
    },
    mutations: {},
    getters: {}
}
