<template>
  <b-navbar toggleable="lg" type="dark" variant="dark">
    <b-navbar-brand class="pr-12" href="#">
      <router-link :to="{name : 'home'}">
        <b-img class="mr-2" src="/logo.png"/>
        <span class="text-white">Square fabric <home/></span>
      </router-link>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item href="#">
          <circle-icon @click="askToken" :title="title"
                       :class="{'text-success': automaticBackupEnabled, 'text-warning': !automaticBackupEnabled}"/>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import CircleIcon from 'vue-material-design-icons/Circle.vue'
import Home from 'vue-material-design-icons/Home.vue'

export default {
  components: {CircleIcon, Home},
  computed: {
    automaticBackupEnabled() {
      return localStorage.getItem('backupToken')
    },
    title() {
      return this.automaticBackupEnabled ? 'Sauvegarde automatique activée, cliquer pour modifier' : 'Sauvegarde automatique desactivée, cliquer pour configurer'
    }
  },
  methods: {
    askToken() {
      const token = prompt('Saisir le nouveau token')
      if (token) {
        localStorage.setItem('backupToken', token)
        window.location = '/'
      }
    }
  }
}
</script>
<style scoped>
img {
  width: 25px;
  background-color: #fafafa;
  padding: 3px;
  border-radius: 4px;
}

</style>
