export default {
    namespaced: true,
    state: {
        projects: [],
        projectId: undefined
    },
    actions: {
        onSetLaize({commit, state, getters}, laize) {
            getters.project.laize = laize
            commit('setProjects', state.projects)
            localStorage.setItem('projects', JSON.stringify(state.projects))
        },
        create({commit, state}, newProject) {
            state.projects.push(newProject)
            commit("setProjects", state.projects)
            localStorage.setItem('projects', JSON.stringify(state.projects))
        },
        remove({commit, state}, project) {
            const projects = state.projects.filter(p => p.id !== project.id)
            commit("setProjects", projects)
            localStorage.setItem('projects', JSON.stringify(projects))
        },
        addPieces({commit, state, getters}, pieces) {
            getters.project.pieces.push(...pieces)
            commit('setProjects', state.projects)
            localStorage.setItem('projects', JSON.stringify(state.projects))
        },
        updatePieces({commit, state, getters}, piece) {
            let updateId = null;
            for (let x = 0; x < getters.project.pieces.length; x++) {
                if (getters.project.pieces[x].id === piece.id) {
                    updateId = x
                }
            }
            getters.project.pieces[updateId] = piece
            commit('setProjects', state.projects)
            localStorage.setItem('projects', JSON.stringify(state.projects))
        },
        removePiece({commit, state, getters}, piece) {
            getters.project.pieces = getters.project.pieces.filter(p => p.id !== piece.id)
            commit('setProjects', state.projects)
            localStorage.setItem('projects', JSON.stringify(state.projects))
        },
        setSpecialRules({commit, state, getters}, specialRules) {
            getters.project.specialRules = specialRules
            commit('setProjects', state.projects)
            localStorage.setItem('projects', JSON.stringify(state.projects))
        },
        update({commit, state}, updatedProject) {
            const project = state.projects.find(p => p.id === updatedProject.id)
            project.name = updatedProject.name
            project.description = updatedProject.description
            commit("setProjects", state.projects)
            localStorage.setItem('projects', JSON.stringify(state.projects))
        },
        updateProjectPieces({commit, state}, {updatedProject, pieces}) {
            const project = state.projects.find(p => p.id === updatedProject.id)
            project.pieces = pieces
            commit("setProjects", state.projects)
            localStorage.setItem('projects', JSON.stringify(state.projects))
        },
        toggleVisibility({commit, state}, updatedProject) {
            const project = state.projects.find(p => p.id === updatedProject.id)
            project.visible = !project.visible
            commit('setProjects', state.projects)
            localStorage.setItem('projects', JSON.stringify(state.projects))

        }
    },
    mutations: {
        setProjects(state, projects) {
            state.projects = projects;
        },
        setCurrentProjectId(state, projectId) {
            state.projectId = parseInt(projectId)
        }
    },
    getters: {
        project(state) {
            return state.projects.find(p => p.id === state.projectId)
        }
    }
};
