import ProjectManager from "./components/ProjectManager.vue";
import PieceEditor from "./components/PieceEditor.vue";
import PiecesDisposition from "./components/PiecesDisposition.vue";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

export default new VueRouter({
    mode: "history",
    base: "/",
    routes: [
        {
            name: "home",
            path: "/",
            component: ProjectManager,
        },
        {
            name: "projects",
            path: "/projects",
            component: ProjectManager,
        },
        {
            name: "editor",
            path: "/editor/:project_id",
            component: PieceEditor,
        },
        {
            name: "disposition",
            path: "/disposition/:project_id",
            component: PiecesDisposition,
        },
    ],
});
