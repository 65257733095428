<template>
  <b-row>
    <b-modal id="split-piece-project-selection" size="xl" hide-footer>
      <template #modal-title>
        <h5 class="modal-title">Selectionner un projet où déplacer les pièces sélectionnées</h5>
      </template>
      <p>{{splitPiecesCount}} pièces selectionnées</p>
      <b-card>
        <b-select v-model="selectedSplitProjectId">
          <b-select-option v-for="(project, x) in splittableProjects" :key="x" :value="project.id">{{
              project.name
            }}
          </b-select-option>
        </b-select>
        <div class="text-center">
          <b-btn :disabled="!selectedSplitProjectId" @click="sendToProject" class="mt-2">Envoyer vers ce projet</b-btn>
        </div>
      </b-card>
    </b-modal>

    <b-col md="2">
      <b-card :class="{'edit-mode': editMode}" header="Paramètres">

        <b-form-group
            label="Laize"
            label-for="laize"
        >
          <b-form-input id="laize" v-model="laize" :disabled="editMode" min="1" placeholder="Laize" step="1"
                        type="number"></b-form-input>
        </b-form-group>
        <hr/>

        <b-form-group
            label="Hauteur"
            label-for="height"
        >
          <b-form-input id="height" v-model="height" min="1" placeholder="Hauteur" step="1"
                        type="number"></b-form-input>
        </b-form-group>

        <b-form-group
            label="Largeur"
            label-for="width"
        >
          <b-form-input id="width" v-model="width" min="1" placeholder="Largeur" step="1" type="number"></b-form-input>
        </b-form-group>

        <b-form-group
            label="Nom"
            label-for="name"
        >
          <b-form-input id="name" v-model="name" placeholder="Nom"></b-form-input>
        </b-form-group>

        <b-form-group
            label="Quantité"
            label-for="quantity"
        >
          <b-form-input id="quantity" v-model="quantity" :disabled="editMode" min="1" placeholder="Quantité" step="1"
                        type="number"></b-form-input>
        </b-form-group>

        <div class="text-center">
          <b-button class="form-button-piece-upsert" :disabled="!canCreatePieces" variant="info" @click="onSetPieces">
            <Cog/>
            Définir pièce
          </b-button>
          <p v-if="editMode" class="text-success mt-2">Mode édition de pièce</p>
        </div>
      </b-card>
    </b-col>
    <b-col md="10">
      <b-card class="pieces-wrapper">
        <template #header>
          Liste des pièces - <b>{{ project.name }}</b>
          <b-badge variant="info" class="ml-2">{{ pieces.length }} pièces</b-badge>
          <router-link :to="{name:'disposition', params:{project_id: $route.params.project_id}}" class="float-right">
            <b-button size="sm">Voir
              <eye/>
            </b-button>
          </router-link>
          <b-button @click="toggleSplitMode"
                    v-b-tooltip.bottom="`Passer en mode découpage pour déplacer un ensemble de pièces vers un autre projet`"
                    class="btn-sm float-right mr-2"
                    :variant="splitMode ? `warning` : `primary`">Découpage
            <scissor/>
          </b-button>
          <b-button
              v-if="splitMode"
              :disabled="!splitMode"
              @click="splitPieces"
              class="float-right btn-sm mr-2"
              variant="success">Exporter
            <upload/>
          </b-button>
        </template>
        <b-table-simple bordered class="text-center" hover responsive small striped>
          <b-thead head-variant="light">
            <b-tr>
              <b-th v-if="splitMode" v-b-tooltip.right="`Selectionner les pièces à découper`">
                <scissor/>
              </b-th>
              <b-th>Nom</b-th>
              <b-th>Largeur</b-th>
              <b-th>Hauteur</b-th>
              <b-th>Actions</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(piece, x) in pieces" :key="x">
              <b-td v-if="splitMode">
                <b-check @change="toggleSplitItem(piece)"></b-check>
              </b-td>
              <b-td>{{ piece.name }}</b-td>
              <b-td>{{ piece.width }}</b-td>
              <b-td>{{ piece.height }}</b-td>
              <b-td>
                <b-button v-b-tooltip.left="`Modifier la pièce ${piece.name}`" class="mr-5" size="sm" variant="primary"
                          @click="editPiece(piece)">
                  <pencil/>
                </b-button>
                <b-button v-b-tooltip.left="`Supprimer la pièce ${piece.name}`" class="ml-2" size="sm" variant="warning"
                          @click="removePiece(piece)">
                  <delete/>
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <br/>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import Cog from 'vue-material-design-icons/Cog.vue'
import Delete from 'vue-material-design-icons/Delete.vue'

import {mapGetters, mapState} from 'vuex'
import Eye from 'vue-material-design-icons/Eye.vue'
import Pencil from 'vue-material-design-icons/Pencil.vue'
import Scissor from 'vue-material-design-icons/ScissorsCutting.vue'
import Upload from 'vue-material-design-icons/Upload.vue'

export default {
  components: {Cog, Delete, Eye, Pencil, Scissor, Upload},
  data() {
    return {
      name: '',
      width: undefined,
      height: undefined,
      quantity: 1,
      editMode: false,
      editId: undefined,
      splitMode: false,
      splitItems: {},
      selectedSplitProjectId: undefined,
      newProjectName: '',
      splitPiecesCount: 0,
    }
  },
  created() {
    this.$store.commit('projects/setCurrentProjectId', this.$route.params.project_id)
  },
  computed: {
    ...mapState('projects', ['projects']),
    ...mapGetters('projects', ['project']),
    splittableProjects() {
      return this.projects.filter(p => p.id !== this.project.id)
    },
    canCreatePieces() {
      return Boolean(this.laize && this.name && this.width && this.height && this.quantity)
    },
    pieces() {
      return this.project.pieces
    },
    laize: {
      set(laize) {
        this.$store.dispatch('projects/onSetLaize', laize)
      },
      get() {
        return this.project.laize
      }
    },
  },
  methods: {
    sendToProject(){
      const keepPieces = []
      const sendProject = this.projects.find(p => p.id === this.selectedSplitProjectId)
      const sendProjectPieces = JSON.parse(JSON.stringify(sendProject.pieces))
      for (const piece of this.pieces) {
        if (this.splitItems[piece.id]) {
          sendProjectPieces.push(piece)
        } else {
          keepPieces.push(piece)
        }
      }
      this.$store.dispatch('projects/updateProjectPieces', {updatedProject: this.project, pieces: keepPieces})
      this.$store.dispatch('projects/updateProjectPieces', {updatedProject: sendProject, pieces: sendProjectPieces})
      this.$bvModal.hide("split-piece-project-selection")
      this.splitItems = {}
      this.$bvToast.toast('Les pièces ont été déplacées', {
        title: 'Succès',
        variant: 'success',
        solid: true
      })
      this.splitMode = false
      this.$forceUpdate()
    },
    updateProjectPieces(project, pieces){
      this.$store.dispatch('projects/updateProjectPieces', {project, pieces})
    },
    toggleSplitItem(piece) {
      this.splitItems[piece.id] = !this.splitItems[piece.id]
    },
    splitPieces() {
      this.splitPiecesCount = Object.keys(this.splitItems).length
      this.$bvModal.show("split-piece-project-selection")
    },
    toggleSplitMode() {
      this.splitMode = !this.splitMode
    },
    editPiece(piece) {
      this.name = piece.name
      this.width = piece.width
      this.height = piece.height
      this.editId = piece.id
      this.editMode = true
    },
    removePiece(piece) {
      this.$store.dispatch('projects/removePiece', piece)
    },
    onSetPieces() {
      if (this.editMode) {
        this.$store.dispatch('projects/updatePieces', {
          id: this.editId,
          laize: this.laize,
          name: this.name,
          height: this.height,
          width: this.width,
        })
        this.name = ''
        this.width = undefined
        this.height = undefined
        this.quantity = 1
        this.editMode = false
      } else {
        const pieces = []
        for (let i = 0; i < this.quantity; i++) {
          pieces.push({
            id: +new Date() + '-' + i,
            name: this.name + '-' + i,
            width: this.width,
            height: this.height,
            laize: this.laize,
          })
        }
        this.$store.dispatch('projects/addPieces', pieces)
        this.$nextTick(() => {
          this.$el.querySelector('#height').focus()
        })
        this.$bvToast.toast('Pièce(s) ajoutée(s) ', {
          variant: 'success'
        })
        this.name = ''
        this.width = undefined
        this.height = undefined
        this.quantity = 1
      }
    }
  },
}
</script>
<style scoped>
.pieces-wrapper {
  overflow-y: auto;
  height: calc(100vh - 90px);
}

.edit-mode {
  background-color: #ddeeee;
}

.form-button-piece-upsert:focus {
  box-shadow: 0 0 25px 0 #0fa4cb;
}
</style>
