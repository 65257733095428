import Vue from "vue";
import App from "./App.vue";
import {BootstrapVue} from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-material-design-icons/styles.css";
import store from "./stores/store";
import router from "./router";
import i18n from "./i18n";
import configureHttp from "./http";
import dayjs from "dayjs";
import './filters/dates.js'

require('dayjs/locale/fr')

dayjs.locale('fr') // use loaded locale globally

Vue.config.productionTip = false;

Vue.use(BootstrapVue);

configureHttp(() => {
    new Vue({
        render: (h) => h(App),
        store,
        router,
        i18n,
    }).$mount("#app");
});
