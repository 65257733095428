<template>
  <div id="app">
    <navbar-menu class="mb-3"/>
    <div class="container-fluid">
      <router-view/>
    </div>
  </div>
</template>

<script>
import NavbarMenu from "@/components/NavbarMenu";
import dayjs from "dayjs";

export default {
  name: 'App',
  components: {NavbarMenu},
  created() {
    this.$store.commit('projects/setProjects', JSON.parse(localStorage.getItem('projects') || '[]'))
    this.backup()
  },
  methods: {
    backup() {
      const todayFmt = dayjs().format('YYYY-MM-DD')
      const lastBackupDay = localStorage.getItem('lastBackupDay')
      if ((!lastBackupDay || lastBackupDay !== todayFmt) && localStorage.getItem('backupToken')) {
        const payload = {projects: localStorage.getItem('projects'), backupToken: localStorage.getItem('backupToken')}
        this.$store.dispatch('settings/backup', payload).then(() => {
          localStorage.setItem('lastBackupDay', todayFmt)
        }).catch(() => {
          this.$bvToast.toast('Echec de la sauvegarde automatique, contactez votre administateur', {
            variant: 'warning'
          })
        })
      } else {
        if (lastBackupDay === todayFmt) {
          console.log('backup already done for today')
        } else {
          console.log('automatic backup not working or not setup')
        }
      }
    }
  }
}
</script>

<style>
#app {
  overflow-x: hidden;
  overflow-y: hidden;
}

.pointer {
  cursor: pointer;
}

body.modal-open {
  overflow: inherit;
  padding-right: 0 !important;
}

@media print
{
  .no-print, .no-print *
  {
    display: none !important;
  }
}
</style>
