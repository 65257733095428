<template>
  <b-row>
    <b-col md="3">
      <b-card header="Edition du projet">
        <b-form-group
            label="Nom du projet"
            label-for="projectname"
        >
          <b-form-input id="projectname" v-model="projectName" placeholder="Nom du projet"></b-form-input>
        </b-form-group>
        <b-form-group
            label="Description"
            label-for="description"
        >
          <b-textarea id="description" v-model="description" placeholder="Description" rows="6"
                      title="Description"></b-textarea>
        </b-form-group>
        <div class="text-center">
          <b-button :disabled="!canCreateProject" variant="info" @click="onCreateProject">
            <plus/>
            Créer un nouveau projet
          </b-button>
        </div>
      </b-card>
    </b-col>
    <b-col md="9">
      <b-card header="Liste des projets">
        <b-row>
          <b-col md="8">
            <b-input v-model="projectFilter" class="mb-3" placeholder="Chercher un projet"></b-input>
          </b-col>
          <b-col md="4" class="text-center pt-2">
            <b-form-checkbox v-model="showAllProjects" name="check-button" switch>
              Afficher tous les projets
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-table-simple bordered class="text-center" hover responsive small striped>
          <b-thead head-variant="light">
            <b-tr>
              <b-th>Nom</b-th>
              <b-th>Description</b-th>
              <b-th>Création</b-th>
              <b-th>Date de mise à jour</b-th>
              <b-th>Actions</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr :variant="project.visible ? 'dark': ''" v-for="project in orderedProjects()" :key="project.id">
              <b-td>{{ project.name }}</b-td>
              <b-td>{{ project.description }}</b-td>
              <b-td>{{ project.createdate | formatDate('DD/MM/YYYY HH:mm') }}</b-td>
              <b-td>{{ project.updateDate | formatDate('DD/MM/YYYY HH:mm') }}</b-td>
              <b-td>
                <router-link :to="{'name': 'disposition', params: {'project_id': project.id}}">
                  <b-button size="sm" variant="secondary">
                    <manjaro/>
                  </b-button>
                </router-link>
                <router-link :to="{'name': 'editor', params: {'project_id': project.id}}">
                  <b-button class="ml-2" size="sm" variant="info">
                    <pencil/>
                  </b-button>
                </router-link>
                <b-button class="ml-2" size="sm" variant="warning" @click="removeProject(project)">
                  <delete/>
                </b-button>
                <b-button class="ml-2" size="sm" variant="primary" @click="edit(project)">
                  <wrench/>
                </b-button>
                <b-button @click="toggleVisibility(project)" size="sm" class="ml-2" variant="light">
                  <eye v-if="project.visible"/>
                  <eye-off v-else/>
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card>
    </b-col>
    <b-modal :title="`Éditer le projet ${editProject && editProject.name}`" id="edit-project-modal" size="xl"
             hide-footer>
      <div v-if="editProject">
        <b-form-input v-model="editProjectName"/>
        <br/>
        <b-form-textarea rows="10" v-model="editProjectDescription"></b-form-textarea>
        <br/>
        <div class="text-center">
          <b-button @click="onProjectSave">Enregistrer</b-button>
        </div>
      </div>
    </b-modal>
  </b-row>
</template>
<script>
import Pencil from 'vue-material-design-icons/Pencil.vue'
import Delete from 'vue-material-design-icons/Delete.vue'
import Plus from 'vue-material-design-icons/Plus.vue'
import Wrench from 'vue-material-design-icons/Wrench.vue'
import Eye from 'vue-material-design-icons/Eye.vue'
import EyeOff from 'vue-material-design-icons/EyeOff.vue'
import Manjaro from 'vue-material-design-icons/Manjaro.vue'
import {mapState} from 'vuex'

export default {
  components: {Pencil, Delete, Plus, Eye, Wrench, Manjaro, EyeOff},
  data() {
    return {
      projectName: '',
      description: '',
      editProject: null,
      editProjectDescription: '',
      editProjectName: '',
      projectFilter: '',
      showAllProjects: false,
    }
  },
  computed: {
    ...mapState('projects', ['projects']),
    canCreateProject() {
      return Boolean(this.projectName && this.description)
    },
  },
  methods: {
    orderedProjects() {
      let projects = JSON.parse(JSON.stringify(this.projects));
      if (this.projectFilter) {
        const filter = this.normalize(this.projectFilter)
        projects = projects.filter(project => {
          return this.normalize(project.name).includes(filter) || this.normalize(project.description).includes(filter)
        })
      }
      if (!this.showAllProjects) {
        projects = projects.filter(project => !project.visible)
      }
      return projects.sort((a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })
    },
    normalize(text) {
      return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
    },
    toggleVisibility(project) {
      this.$store.dispatch('projects/toggleVisibility', project)
      this.$forceUpdate()
    },
    onProjectSave() {
      this.$store.dispatch('projects/update', {
        id: this.editProject.id,
        description: this.editProjectDescription,
        name: this.editProjectName,
      })
      this.$bvModal.hide('edit-project-modal')
    },
    edit(project) {
      this.editProject = project
      this.editProjectName = project.name
      this.editProjectDescription = project.description
      this.$bvModal.show('edit-project-modal')
    },
    onCreateProject() {
      this.$store.dispatch('projects/create', {
        id: +new Date(),
        description: this.description,
        name: this.projectName,
        laize: 140,
        createDate: +new Date(),
        updateDate: +new Date(),
        pieces: [],
      })
      this.description = '';
      this.projectName = '';
    },
    removeProject(project) {
      if (confirm('Supprimer projet ?')) {
        this.$store.dispatch('projects/remove', project)
        this.$bvToast.toast('Projet supprimé', {
          variant: 'success'
        })
      }
    }
  },
}
</script>

