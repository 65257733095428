import Vue from "vue";
import Vuex from "vuex";
import settings from "./settings";
import projects from "./projects";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        settings,
        projects,
    },
});
