<template>
  <div>
    <b-button variant="dark" v-b-tooltip.left="`Gérer des règles de positionnement spéciales`" size="sm"
              v-b-modal.special-rules-modal>
      <auto-fix/>
    </b-button>
    <b-modal id="special-rules-modal" size="xl" hide-footer @show="onModalShow">
      <template #modal-title>
        <h5 class="modal-title">Règles spéciales</h5>
      </template>
      <b-card class="card-item mb-4" v-for="(rule, x) of rules" :key="x">
        <b-row>
          <b-col md="12">
            <b>Règle {{ x + 1 }}</b>
            <b-button v-b-tooltip="`Supprimer la règle`" class="float-right" size="sm" variant="danger"
                      @click="removeRule(x)">
              <trash-can/>
            </b-button>
          </b-col>
        </b-row>
        <br/>
        <b-row>
          <b-col md="3">Pièce concernée</b-col>
          <b-col md="9">
            <b-form-select v-model="rule.source" :options="sourceOptions"/>
          </b-col>
        </b-row>
        <br/>
        <b-row>
          <b-col md="3">A droite de</b-col>
          <b-col md="9">
            <b-form-select v-model="rule.rightOf" :options="sourceOptions"/>
          </b-col>
        </b-row>
        <br/>
        <b-row>
          <b-col md="3">En dessous de</b-col>
          <b-col md="9">
            <b-form-select v-model="rule.bottomOf" :options="sourceOptions"/>
          </b-col>
        </b-row>
      </b-card>
      <b-button @click="addRule">
        <plus/>
        Nouvelle règle
      </b-button>
      <b-button variant="primary" class="float-right" @click="saveRules">
        Enregistrer et appliquer
      </b-button>

    </b-modal>
  </div>
</template>
<script>
import Plus from 'vue-material-design-icons/Plus.vue'
import AutoFix from 'vue-material-design-icons/AutoFix.vue'
import TrashCan from 'vue-material-design-icons/TrashCan.vue'
import {mapGetters} from 'vuex'

export default {
  components: {Plus, AutoFix, TrashCan},
  data() {
    return {
      rules: []
    }
  },
  methods: {
    onModalShow() {
      this.rules = JSON.parse(JSON.stringify(this.project.specialRules || []))
    },
    addRule() {
      this.rules.push({
        source: '',
        rightOf: '',
        bottomOf: '',
      })
    },
    removeRule(index) {
      this.rules.splice(index, 1)
    },
    saveRules() {
      for (let rule of this.rules) {
        if (!rule.source || (!rule.rightOf && !rule.bottomOf)) {
          this.$bvToast.toast('Toutes les règles doivent être remplies', {
            title: 'Erreur',
            variant: 'danger',
            solid: true
          })
          return
        }
      }
      this.$store.dispatch('projects/setSpecialRules', this.rules)
      this.$bvModal.hide('special-rules-modal')
      this.$bvToast.toast('Règles enregistrées', {
        title: 'Succès',
        variant: 'success',
        solid: true
      })
      this.$emit('onRulesApplied')
    }
  },
  computed: {
    ...mapGetters('projects', ['project']),
    sourceOptions() {
      return this.project.pieces.map(p => p.name)
    }
  }
}
</script>
<style scoped>
.card-item {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)
}
</style>
