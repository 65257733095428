import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";

export default (callback) => {
    const instance = axios.create({
        timeout: 15000,
        headers: {
            "Content-Type": "application/json",
        },
    });
    Vue.use(VueAxios, instance);
    Vue.axios.defaults.baseURL = (process.env.VUE_APP_TRANSISTOR_API || "") + "/v1/";
    callback();
};

